import { connect } from 'react-redux'
import ResetPasswordForm from './ResetPasswordForm'
import digitalStoreSdk from '../../../digitalStoreSdk'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({ dispatch })

const mergeProps = ({ state }, { dispatch }, ownProps) => {
  const { onSubmissionComplete } = ownProps

  const onSubmit = ({ password }) => {
    const resetToken = window.location.href.split('reset_token=')[1]
    return digitalStoreSdk.auth.changePassword({ resetToken, password })
    .then(() => {
      onSubmissionComplete()
    })
    .catch((err) => {
      console.log(err)
    })
  }

  return {
    ...ownProps,
    onSubmit
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ResetPasswordForm)
