import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'

// Add all reducers here
const reducers = {
  form: formReducer
}


const CreateStore = initialState => {
  return createStore(
    combineReducers({ ...reducers }),
    initialState,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware
      )
    )
  )
}

export default CreateStore

